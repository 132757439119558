// directives.js
export const drag = {
    inserted: function (el) {
        let oDiv = el;
        let parentDiv = oDiv.parentElement;
        oDiv.onmousedown = function (e) {
            let disX = e.clientX - oDiv.offsetLeft;
            let disY = e.clientY - oDiv.offsetTop;
            document.onmousemove = function (e) {
                let left = e.clientX - disX;
                let top = e.clientY - disY;
                // 限制左边界
                left = Math.max(left, 0);
                // 限制上边界
                top = Math.max(top, 0);
                // 限制右边界
                left = Math.min(left, parentDiv.offsetWidth - oDiv.offsetWidth);
                // 限制下边界
                top = Math.min(top, parentDiv.offsetHeight - oDiv.offsetHeight);
                oDiv.style.left = left + "px";
                oDiv.style.top = top + "px";
            };
            document.onmouseup = function () {
                document.onmousemove = null;
                document.onmouseup = null;
            };
        };
    },
};
