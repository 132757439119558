import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import "lib-flexible";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import qs from "qs";
import { drag } from "./utils/directives";
Vue.directive("drag", drag);
// 自定义组件-文本超出隐藏
import OverflowTxt from "./components/overflowTxt.vue";

// 全局注册组件
Vue.component("overflow-txt", OverflowTxt);

// 响应式通用样式
import "./assets/css/responsive.css";
// 公共样式
import "./assets/css/public.css";

import utils from "./utils/auth";

import {
    parseTime,
    resetForm,
    addDateRange,
    selectDictLabel,
    selectDictLabels,
    handleTree,
    queryToSql,
    queryToSqlShow,
} from "@/utils/ruoyi";
import "echarts";
import VueEcharts from "vue-echarts";
import plugins from "./plugins"; // plugins

// 导入qiankun.js
import { registerMicroApps, setDefaultMountApp, start } from "qiankun";
// 全局组件挂载
Vue.directive("drag", drag);
Vue.component("VueEcharts", VueEcharts);
var _versions = store.state.version;

// 将utils对象的方法添加到Vue的原型上
Object.keys(utils).forEach((key) => {
    Vue.prototype["$$" + key] = utils[key]; // 使用$$前缀避免与Vue内置属性冲突
});

// 注册子应用
registerMicroApps([
    {
        name: "vue app", // 子应用名称
        entry: "//localhost:8082", // 子应用入口
        container: "#sub", // 子应用所在容器
        activeRule: "/vue", // 子应用触发规则（路径）
    },
]);

// 启动默认应用
// setDefaultMountApp("/vue");

// 开启服务
start({
    prefetch: true, // 取消预加载
});

var baseURL = store.getters.baseURL;
Vue.prototype.resetForm = resetForm;
Vue.prototype.parseTime = parseTime;
Vue.prototype.handleTree = handleTree;
Vue.prototype.queryToSqlShow = queryToSqlShow;
Vue.prototype.queryToSql = queryToSql;
Vue.prototype.role = "";
Vue.prototype.userInfo = "";
Vue.prototype.$axios = axios;
Vue.prototype.instance = Vue.prototype.$axios.create({
    baseURL,
});
//请求拦截器
Vue.prototype.instance.interceptors.request.use(
    (config) => {
        //请求之前的操作  Authorization
        config.headers.Authorization = "Bearer " + utils.getToken();
        config.headers["X-From-Business-Line"] = "tpa";
        config.headers["X-From-Tenant"] = "9999";
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//响应拦截器
Vue.prototype.instance.interceptors.response.use(
    (res) => {
        let token = localStorage.getItem("AUTH_TOKEN");
        if (res.data.code == 401 && token) {
            let localVueVersion = localStorage.getItem("vueVersion");
            localStorage.clear();
            localStorage.setItem("vueVersion", localVueVersion);
            window.alert("抱歉，当前账号登录信息已过期，请重新登录");
            utils.removeDialog();
            router.replace({
                path: "/",
            });
            // query: {redirect: router.currentRoute.fullPath}//登录成功后跳入浏览的当前页面
        }
        return res;
    },
    (error) => {
        let token = localStorage.getItem("AUTH_TOKEN");
        if (error.response && error.response.status && token) {
            let localVueVersion = localStorage.getItem("vueVersion");
            localStorage.clear();
            localStorage.setItem("vueVersion", localVueVersion);
            window.alert("抱歉，当前账号登录信息已过期，请重新登录");
            utils.removeDialog();
            router.replace({
                path: "/",
            });
        }
    }
);
Vue.config.productionTip = false;

//路由跳转前做判断
router.beforeEach((to, from, next) => {
    let AUTH_TOKEN = utils.getToken(); //从sessionStorage中获取是否已登陆过的信息
    // if(to.meta.title){
    //   document.title = to.meta.title
    // }
    if (AUTH_TOKEN) {
        next();
    } else {
        if (to.path == "/") {
            next();
        } else {
            next({
                replace: true,
                name: "Login",
            });
        }
    }
});
Vue.use(ElementUI, { size: "small", zIndex: 3000 });
Vue.use(plugins);
Vue.use(VueAxios, axios);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

// 获取 URL 参数
Vue.prototype.getQueryString = function (name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var href = window.location.href;
    href = decodeURI(href);
    var r = href.substr(href.indexOf("?") + 1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
};
//获取商品品类树option
Vue.prototype.getCategoryTree = function () {
    this.instance
        .get("/tpa/api/goods/category/getCategoryTree", {})
        .then((res) => {
            if (res.data.code == 200) {
                res.data.data.length > 0 &&
                    res.data.data.forEach((val, key) => {
                        val.value = val.id;
                        val.label = val.name;
                        val.children = val.goodsCategoryDtoList;
                        val.children.length > 0 &&
                            val.children.forEach((value, index) => {
                                value.value = value.id;
                                value.label = value.name;
                            });
                    });
                this.categoryTreeOption = res.data.data;
            }
        });
};
//获取枚举信息
Vue.prototype.getPublicEnum = async function (params, key, caseStep) {
    await this.instance
        .get("/tpa/api/enum/" + params + "?step=" + caseStep, {})
        .then((res) => {
            if (res.data.code == 200) {
                this[key] = res.data.data;
            }
        });
};
// 时间格式化
Vue.prototype.dateFormatting = function (date, fmt) {
    var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length == 1
                    ? o[k]
                    : ("00" + o[k]).substr(("" + o[k]).length)
            );
    return fmt;
};
Array.prototype.indexOf = function (val) {
    for (var i = 0; i < this.length; i++) {
        if (this[i] == val) return i;
    }
    return -1;
};
Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
        this.splice(index, 1);
    }
};

Vue.prototype.recordEventTracking = function (caseNo, caseStep, eventName) {
    console.log("埋点");
    let params = {
        caseNo: caseNo,
        caseStep: caseStep,
        eventName: eventName,
    };
    this.instance.post("/tpa/api/event/tracking/record", params).then((res) => {
        if (res.data.code === 200) {
            console.log("埋点成功");
        }
    });
};
