import Vue from "vue";
import router from "../router";
import axios from "axios";

const refreshTokenKey = "ALL_TOKEN";
//获取token
var getToken = function () {
    return localStorage.getItem("AUTH_TOKEN");
};
//定时刷新token
var intervalRefreshToken = function () {
    setInterval(function () {
        if (router.history.current.path != "/") {
            getTimeToken(refreshTokenKey, 1000 * 60 * 60 * 2);
            // getTimeToken(refreshTokenKey,1000*60*0.1);
        }
    }, 1000 * 60 * 60);
};
//设置带时间的token
var setTimeToken = function (value) {
    var curTime = new Date().getTime();
    localStorage.setItem(
        refreshTokenKey,
        JSON.stringify({ data: value, time: curTime })
    );
};
//获取带时间的token，并传入时间差exp
var getTimeToken = function (key, exp) {
    var data = localStorage.getItem(key);
    var dataObj = JSON.parse(data);
    if (new Date().getTime() - dataObj.time > exp) {
        var refreshToken = dataObj ? dataObj.data : null;
        var json = {
            refreshToken: refreshToken ? refreshToken.refreshToken : null,
            accessToken: refreshToken ? refreshToken.accessToken : null,
        };
        Vue.prototype
            .instance({
                method: "post",
                url: "/login/refreshToken",
                data: json,
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                },
            })
            .then((res) => {
                var data = res && res.data ? res.data : null;
                if (data && data.code == 200) {
                    setTimeToken(data.data);
                    // Vue.prototype.$axios.defaults.headers.common['Authorization'] =data.data.token;
                    localStorage.setItem("AUTH_TOKEN", data.data.accessToken);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

// 处理后台较长错误提示信息
let messageLineFeed = function (messageStr) {
    let str = messageStr;
    let tmpIndex = messageStr.indexOf(":");
    let messageData = {
        message: str,
        type: "error",
    };
    if (tmpIndex >= 0) {
        let tmpStr = messageStr.slice(tmpIndex + 1, messageStr.length);
        let tmpArr = tmpStr.split(",");
        str = tmpArr.join(" <br/> ");
        messageData.message = str;
        messageData.dangerouslyUseHTMLString = true;
    }
    return messageData;
};

function getDate() {
    var date = new Date(); // 获取时间
    var year = date.getFullYear(); // 获取年
    var month = date.getMonth() + 1; // 获取月
    var strDate = date.getDate(); // 获取日
    // var day = date.getDate() //
    var day = "日一二三四五六".charAt(new Date().getDay()); // 周一返回的是1，周六是6，但是周日是0
    var hour = date.getHours(); // 获取小时
    var minute = date.getMinutes(); // 获取分钟
    var second = date.getSeconds(); // 获取秒
    // 由于部分业务处理 是需要月份日份前面有0 故新增一个函数
    //   console.log( year + '-' + getNum(month) + '-' + getNum(strDate) + ' 星期'+day)
    return year + "-" + getNum(month) + "-" + getNum(strDate);
}
function getNum(i) {
    return i < 10 ? "0" + i : i;
}

// 生成水印
let warterMark = {};
let setWatermark = (str, str1) => {
    let id = "1.23452384164.123412415";
    if (document.getElementById(id)) {
        document.getElementById(id) &&
            document.body.removeChild(document.getElementById(id));
        warterMark.set(str, str1);
    }

    if (document.getElementById(id) !== null) {
        document.body.removeChild(document.getElementById(id));
    }

    let can = document.createElement("canvas"); // 创建一个画布
    can.width = 400; // 设置宽度
    can.height = 330; // 高度

    let cans = can.getContext("2d");
    cans.rotate((-15 * Math.PI) / 180); // 水印旋转角度    0 水平
    cans.font = "18px Vedana"; // 字体大小
    cans.fillStyle = "#262D3C"; // 水印的颜色
    cans.textAlign = "left"; // 设置文本内容的当前对齐方式
    cans.textBaseline = "Middle"; // 设置在绘制文本时使用的当前文本基线
    cans.globalAlpha = 0.05; // 透明度
    cans.fillText(str, can.width / 3, can.height / 2); // 在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
    cans.fillText(str1, can.width / 3, can.height / 2.5); // 根据需求可添加多行水印，在方法中添加str1

    let div = document.createElement("div");
    div.id = id;
    warterMark.id = id;
    div.style.pointerEvents = "none";
    div.style.top = "28px";
    div.style.left = "0px";
    div.style.position = "fixed";
    div.style.zIndex = "100000";
    div.style.width = document.documentElement.clientWidth + "px";
    div.style.height = document.documentElement.clientHeight + "px";
    //   div.style.width = document.documentElement.clientWidth - 30 + 'px'
    div.style.background =
        "url(" + can.toDataURL("image/png") + ") left top repeat";
    document.body.appendChild(div);
    return id;
};
// 该方法只允许调用一次
warterMark.set = (str, str1) => {
    let id = setWatermark(str, str1);
    let setTime = setInterval(() => {
        if (
            document.getElementById(id) === null &&
            localStorage.getItem("userInfo")
        ) {
            id = setWatermark(str, str1);
            clearInterval(setTime);
        }
    }, 500);
    window.onresize = () => {
        setWatermark(str, str1);
    };
};
warterMark.remove = () => {
    document.getElementById(warterMark.id) &&
        warterMark.id &&
        document.body.removeChild(document.getElementById(warterMark.id));
};

// 检测版本更新 - 废弃
let isNewVersion = () => {
    let url = `//${
        window.location.host
    }/version.json?t=${new Date().getTime()}`;
    axios.get(url).then((res) => {
        if (res.status === 200) {
            let vueVersion = res.data.version || "1.0.0";
            let localVueVersion = localStorage.getItem("vueVersion");
            localStorage.setItem("vueVersion", vueVersion);
            if (localVueVersion && localVueVersion != vueVersion) {
                console.log("检测到版本更新，需要进行操作");
                localStorage.setItem("version", "true");
                // window.location.reload(true)
                return true;
            }
        }
    });
};

// 获取文件后缀
function getFileSuffix(fileName) {
    let fileNameArr, tmpSuffix;
    if (fileName.indexOf("?") != -1) {
        fileNameArr = fileName.split("?")[0];
        fileNameArr = fileNameArr.split(".");
        tmpSuffix = fileNameArr[fileNameArr.length - 1];
    } else {
        fileNameArr = fileName.split(".");
        tmpSuffix = fileNameArr[fileNameArr.length - 1];
    }
    return tmpSuffix ? tmpSuffix.toLowerCase() : null;
}

// 文件后缀是否图片
function isFileSuffixImg(suffixStr) {
    let imgSuffixList = [
        "tiff",
        "jfif",
        "bmp",
        "webp",
        "gif",
        "svg",
        "jpg",
        "svgz",
        "png",
        "jpeg",
        "ico",
        "xbm",
        "dib",
        "pjp",
        "apng",
        "tif",
        "pjpeg",
        "avif",
    ];

    return imgSuffixList.indexOf(suffixStr) != -1;
}

function copyText(text, tooltipStr) {
    let textarea = document.createElement("textarea"); //创建临时元素
    textarea.value = text; //设置要复制的内容
    document.body.appendChild(textarea); //将该元素添加到页面上
    textarea.select(); //选中输入框中的内容
    try {
        let successful = document.execCommand("copy"); //执行复制命令
        if (successful) {
            this.$message.success(`${tooltipStr}复制成功！`);
        } else {
            console.log("无法复制！");
        }
    } catch (err) {
        console.error("无法复制！", err);
    } finally {
        document.body.removeChild(textarea); //移除临时元素
    }
}

// 移除dialog弹窗
function removeDialog() {
    let modelEl = document.getElementsByClassName("v-modal")[0];
    modelEl && modelEl.remove();
    let dialogEl = document.getElementsByClassName("el-dialog__wrapper");
    for (let i = 0; i < dialogEl.length; i++) {
        const element = dialogEl[i];
        const tmpIndex = element.style.zIndex || 0;
        tmpIndex > 1000 && element.remove();
    }
}

let utils = {
    getToken: getToken,
    intervalRefreshToken: intervalRefreshToken,
    setTimeToken: setTimeToken,
    getTimeToken: getTimeToken,
    messageLineFeed: messageLineFeed,
    warterMark: warterMark,
    getDate: getDate,
    isNewVersion: isNewVersion,
    getFileSuffix: getFileSuffix,
    isFileSuffixImg: isFileSuffixImg,
    copyText: copyText,
    removeDialog: removeDialog,
};

export default utils;
