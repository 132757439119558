
export default {
    props: {
        txt: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            isOverflow: false
        };
    },
    mounted() {
        // if (!this.txt) {
        //     return;
        // } else {
        //     console.log('-0');
        //     this.updateIsOverflow();
        // }
    },
    methods: {
        updateIsOverflow() {
            let txtEl = this.$refs.showTxt;

            if (txtEl.clientWidth > 0) {
                this.isOverflow = this.hasTextOverflowEllipsisAndOverflow(this.$refs.showTxt);
            } else {
                this.findCurrentElAttrbute();
            }
        },
        hasTextOverflowEllipsisAndOverflow(element) {
            // 获取元素的计算样式
            const computedStyle = window.getComputedStyle(element);

            // 检查是否设置了text-overflow: ellipsis
            if (computedStyle.textOverflow === 'ellipsis') {
                // 检查是否设置了overflow为非visible的值，并且white-space为nowrap
                if (computedStyle.overflow !== 'visible' && computedStyle.whiteSpace === 'nowrap') {
                    // 尝试使用scrollWidth（或offsetWidth）与clientWidth进行比较
                    // 如果scrollWidth（或offsetWidth）大于clientWidth，则说明文本溢出了
                    if (element.scrollWidth > element.clientWidth || element.offsetWidth > element.clientWidth) {
                        return true;
                    }
                }
            }

            return false;
        },
        findCurrentElAttrbute() {
            let findCurrentEl = this.$refs.showTxt;
            const textContent = findCurrentEl.textContent;
            findCurrentEl.setAttribute('overflowSign', textContent);

            let currentElement = findCurrentEl.parentNode;
            let level = 1;
            let targetElement = null;
            while (currentElement) {
                const computedStyle = window.getComputedStyle(currentElement);
                if (computedStyle.display === 'none') {
                    targetElement = currentElement;
                    break;
                }
                currentElement = currentElement.parentNode;
                level++;
            }
            if (targetElement) {
                // console.log(`在第${level}层父元素（元素id：${targetElement.id}）的display属性为none`);
                // 克隆该元素
                const clonedElement = targetElement.cloneNode(true);
                clonedElement.setAttribute('overflowSign', textContent);
                clonedElement.style.display = 'block';
                clonedElement.style.height = '0px';
                clonedElement.style.width = targetElement.parentElement.clientWidth + 'px';
                clonedElement.style.overflow = 'hidden';
                // 将克隆元素添加到body元素下
                document.body.appendChild(clonedElement);
                const targetElements = clonedElement.getElementsByClassName('hideTxt');
                for (let i = 0; i < targetElements.length; i++) {
                    let findEl = targetElements[i];
                    if (findEl.getAttribute('overflowSign') == textContent) {
                        if (findEl.scrollWidth > findEl.clientWidth || findEl.offsetWidth > findEl.clientWidth) {
                            this.isOverflow = true;
                            console.log('结果：true');
                        } else {
                            this.isOverflow = false;
                            console.log('结果：false');
                        }
                    }
                }
                clonedElement.parentNode.removeChild(clonedElement);
            }
        }
    },
    updated() {
        // this.updateIsOverflow();
    },
    watch: {
        txt: {
            handler(val) {
                setTimeout(() => {
                    val && this.updateIsOverflow();
                }, 1000);
            },
            immediate: true
        }
    }
};
